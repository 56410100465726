@font-face {
    font-family: 'Nunito-Regular';
    src:
        url('./assets/fonts//Nunito-Regular.woff') format('woff'),
        url('./assets/fonts/Nunito-Regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

body {
    margin: 0;
    font-family: 'Nunito-Regular' !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: transparent;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.chatMessage p {
    margin-bottom: 0;
}